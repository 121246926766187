<template>
    <div>
        <div style="height:100vh;" class="d-flex justify-content-center align-items-center shadow-lg rounded-lg">
            <div style="width:100%">
                <div class="text-center">
                    <p class="animate__animated animate__bounce animate__infinite text-2xl font-semibold text-center">
                        Loading ...
                    </p>
                </div>  
            </div>
        </div>
    </div>
</template>
<script>
export default{
    mounted() {
        if(!localStorage.getItem('refresh')){
            localStorage.setItem('refresh',1)
            location.reload();
        }else{
            this.$router.push('/admin')
            setTimeout(() => {
                localStorage.removeItem('refresh')
            }, 1000);
        }
    },
}
</script>